import React from "react";
import { Box, Container } from "@material-ui/core";

import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import useHomeStyles from "../styles";
import PartnerRolling from "src/components/public/lists/SliderList";
import clsx from "clsx";

import MainLeftOBJ from "@assets/images/public/screen/landingHomeScreen/main-visual/main-left-obj.png";
import MainRightOBJ from "@assets/images/public/screen/landingHomeScreen/main-visual/main-right-obj.png";

export default function MainVisualSection() {
  const classes = useHomeStyles();

  return (
    <Box className={classes.visual_section} component="section">
      <Container className={classes.container}>
        <Box className={classes.visual_section_inner}>
          <img
            src={MainLeftOBJ}
            alt="main-left-obj"
            className={clsx("main-obj", "left-obj")}
          />
          <img
            src={MainRightOBJ}
            alt="main-right-obj"
            className={clsx("main-obj", "right-obj")}
          />
          <GoonoTypography
            type="h1"
            preLine
            keepAll
            className={"visual_section_inner_title"}
          >
            연구자료는
            <strong> 프라이빗하게 보관</strong>
            하고,
            <br />
            기밀자료는
            <strong> 자유롭게 공유</strong>
            하세요!
          </GoonoTypography>

          <GoonoTypography
            preLine
            className={"visual_section_inner_desc"}
            keepAll
            children={
              "ISO, GS 인증 등 국내외 표준을 충족하는 서비스로 \n 국내 2,000곳 이상의 조직에 최고의 기술을 제공합니다."
            }
          />

          <Box className={"rolling_box"}>
            <PartnerRolling />
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
