import React from "react";
import GlobalComponentManager from "./GlobalComponentManager";
import RoutingContents from "./RoutingContents";
import loadable from "@loadable/component";
import { useLanguageSyncor } from "./hooks/useLanguageSyncor";
import { useHistory } from "react-router-dom";

const PublicRouteControllorLazy = loadable(
  () => import(`./ControllorByRoute/PublicRouteControllor`)
);

const LandingRouteControllorLazy = loadable(
  () => import(`./ControllorByRoute/LandingRouteControllor`)
);

export default function GoonoRoutingManager() {
  const history = useHistory();
  const [paintingDone, setPaintingDone] = React.useState(false);

  React.useEffect(() => {
    if (history.location.pathname.includes(`certification`)) {
      const fullPathWithQuery =
        window.location.pathname + window.location.search;

      window.location.href = `${process.env
        .REACT_APP_SERVICE_URL!}${fullPathWithQuery}`;
    }
  }, []);

  React.useEffect(() => {
    const handlePaintEnd = () => setPaintingDone(true);
    window.addEventListener("load", handlePaintEnd);
    return () => window.removeEventListener("load", handlePaintEnd);
  }, []);

  /** language syncor는 언어 변경 시 전체 UI
   * rerendering 해줘야 해서 route controllor에 넣지 말아주세요 */
  useLanguageSyncor();

  return (
    <React.Fragment>
      <GlobalComponentManager />
      <RoutingContents />

      {/** 렌더링과 상관 없음 / 페인트 끝나면 로드되는 라우트 별 컨트롤러들 */}
      {/** 최적화 때문에 페인트 끝나고 기능 로드하는것 / 무조건 lazy load로 불러올 것 */}
      {paintingDone && (
        <React.Fragment>
          <PublicRouteControllorLazy />
          <LandingRouteControllorLazy />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
