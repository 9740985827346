export const ScreenURL = {
  HOME: "/",
  SERVICE_GOONO: "/service/cloud-eln",
  SERVICE_BUILTIN: "/service/on-premise-eln",
  SERVICE_STRONGBOX: "/service/strongbox",
  // USE CASE --
  USECASE_STARTUP: "/use-case/startup",
  USECASE_DIGITAL_TRANSFORMATION: "/use-case/digital-transformation",
  USECASE_DOC_SHARING: "/use-case/doc-sharing",
  USECASE_FREELANCER: "/use-case/freelancer",
  //  -- USE CASE
  PRICE: "/pricing",
  CONTACT: "/contact",
  CONTACT_TYPE: "/contact/:type",
  TERM: "/terms/:term",
  TERM_INFO: "/terms",
  FORBIDDEN: "*",
} as const;
