import React from "react";
import GoonoDialogManager from "./GoonoDialogManager";
import GoonoSnackbarManager from "./GoonoSnackbarManager";

export default function GlobalComponentManager() {
  return (
    <React.Fragment>
      <GoonoDialogManager />
      <GoonoSnackbarManager />
    </React.Fragment>
  );
}
