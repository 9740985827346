import { makeStyles, Theme, alpha } from "@material-ui/core";
import { theme, service, system, typo, ect } from "@theme";
import { landingTheme } from "src/styles/landingTheme";
import { IMAGES } from "@assets/images/public";
import EastIcon from "@assets/images/public/icon/icon-east.svg";
import BannerBook from "@assets/images/public/screen/landingHomeScreen/content-image/banner-book.png";
import BannerLock from "@assets/images/public/screen/landingHomeScreen/content-image/banner-lock.png";

const useHomeStyles = makeStyles((MuiTheme: Theme) => ({
  container: {
    position: "relative",
    ...landingTheme.layout.MAX_CONTAINER,
  },
  visual_section: {
    paddingTop: 90,
    background: `url(${IMAGES.landingHomeScreen.mainVisual.mainBg}) no-repeat center top / cover`,
  },
  visual_section_inner: {
    position: "relative",
    paddingTop: 58,
    paddingBottom: 150,
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: theme.white,

    "& .main-obj": {
      position: "absolute",
      top: 0,

      "&.left-obj": {
        left: 60,
        top: 169,
      },
      "&.right-obj": {
        right: 60,
        top: 88,
      },
    },

    "& .visual_section_inner_title": {
      fontSize: 45,
      fontWeight: 400,
      lineHeight: 1.38,
      letterSpacing: "-1.35px",
      position: "relative",
      backdropFilter: "blur(5px)",

      "& strong": {
        fontWeight: 700,
      },
    },
    "& .visual_section_inner_desc": {
      marginTop: 32,
      fontSize: 20,
      opacity: 0.8,
      backdropFilter: "blur(5px)",
    },

    "& .rolling_box": {
      marginTop: 120,
      width: "100%",
    },
  },

  service_section_list: {
    width: "100%",
    paddingTop: 120,
    paddingBottom: 80,
    transform: "translateZ(-1px)",
  },
  service_list_item: {
    marginBottom: 120,
    background: theme.white,
    overflow: "hidden",
    transformOrigin: "bottom right",
    "&:last-child": { marginBottom: 0 },

    "&:nth-child(1), &:nth-child(3)": {
      "& .service-item-img-box img": { maxWidth: "90%" },
    },
    "&:nth-child(1)": {
      "& .service-item-img-box": {
        alignItems: "flex-end",
        justifyContent: "center",
      },
      "& .service-item-badge": {
        background: service.primary_50,
        color: service.primary_600,
      },
    },
    "&:nth-child(2)": {
      "& .service-item-badge": {
        background: ect.deep_purple_50,
        color: ect.deep_purple_800,
      },
    },
    "&:nth-child(3)": {
      "& .service-item-badge": {
        background: system.success_50,
        color: system.success_800,
      },
    },
    "&:nth-child(2), &:nth-child(3)": {
      "& .service-item-img-box": {
        alignItems: "center",
        justifyContent: "center",
      },
    },
  },

  "@keyframes functionScrollani": {
    "0%": {
      transform: "translateY(50%)",
      opacity: 0,
    },
    "30%": { opacity: 0 },
    "100%": { transform: "translateY(0)", opacity: 1 },
  },

  service_item_inner_active: {
    "&& .service-item-img-box img": {
      // animation: "$functionScrollani 1s ease-in-out",
      // animationFillMode: "both",
    },
    "&& .service-item-title, && .service-item-list, && .service-item-description-box":
      {
        opacity: 1,
        transform: "translateY(0)",
      },
  },

  service_section_list_inner: {
    display: "flex",
    justifyContent: "space-between",
    ...landingTheme.layout.MAX_CONTAINER,
    margin: "0 auto",

    "& .service-inner-img-box": {
      width: 644,
      height: 390,
      borderRadius: 8,
      background: "#F6FBFF",
      display: "flex",
      position: "sticky",
      top: 180,
      right: 20,

      "& .img-wrap": {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "100%",
        ...theme.flex_center,

        "&:nth-child(1), &:nth-child(3)": {
          "& img": { maxWidth: "90%" },
        },
        "& img": {
          maxWidth: "100%",
        },
      },
    },
  },
  service_item_inner: {
    alignItems: "center",
    justifyContent: "space-between",

    "& .service-item-img-box": {
      width: 644,
      height: 390,
      borderRadius: 8,
      background: "#F6FBFF",
      position: "relative",
      display: "flex",

      "& img": {
        position: "absolute",
        maxWidth: "100%",
      },
    },

    "& .service-item-badge": {
      marginBottom: 16,
      height: 44,
      padding: "0 14px",
      borderRadius: 4,
      ...typo.b2_b,
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
    },
    "& .service-item-description-box": {
      transition: "all 1s ease-in-out",
      opacity: 0,
      transform: "translateY(50%)",
    },
    "& .service-item-title": {
      marginBottom: 40,
      color: theme.gray_9_title,
      letterSpacing: -1,
      ...landingTheme.typography.h2,
      ...theme.font_bold,
      lineHeight: 1.3,
    },

    "& .service-item-list": {
      marginBottom: 44,
      padding: 0,
      transition: "all 0.8s ease-in-out 0.3s",
      opacity: 0,
      transform: "translateY(50%)",
    },
    "& .service-item-list-item": {
      padding: 0,
      marginBottom: 10,
      letterSpacing: -1,
      color: theme.gray_6,
      fontSize: 16,
      ...theme.font_medium,

      "&:last-child": { marginBottom: 0 },
    },
    "& .service-item-button-box": {
      gap: 20,
    },
    "& .service-item-button": {
      gap: 4,
      color: theme.gray_7_text,
      ...typo.b2_b,
      transition: "all 0.25s ease-in-out",
      "&:hover": {
        gap: 8,
        color: theme.gray_9_title,
      },
    },
  },

  customers_section: {
    background: "linear-gradient(92deg, #F5F1FF 1.13%, #EDF6FC 100.47%)",
  },
  customers_section_inner: {
    minHeight: 360,
    alignItems: "center",
    justifyContent: "space-between",
    "& .sub-title": {
      color: "#9B94C3",
      fontSize: 18,
    },
    "& .title": {
      fontSize: 36,
      color: theme.gray_8,
      ...theme.font_bold,
    },
  },
  customers_box: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiDivider-root": {
      height: 182,
      width: 1,
      background: "#C8DAF9",
      margin: "0 60px",
    },
  },
  customers_box_item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",

    "& .customers_box_item_img": {
      alignItems: "center",
      justifyContent: "center",
      minHeight: 100,
    },
    "& .customers_box_item_number": {
      marginTop: 12,
      fontSize: 40,
      color: "#15336E",
      ...theme.font_bold,
    },
    "& .customers_box_item_text": {
      color: "#7888A2",
      ...typo.b1_m,
    },
  },
  use_case_section: {
    width: "100%",
  },
  use_case_section_inner: {
    paddingTop: 100,
    paddingBottom: 120,

    "& .section-title": {
      fontSize: 36,
      ...theme.font_bold,
      textAlign: "center",
    },
  },
  use_case_intro_content: {
    marginTop: 80,
    display: "flex",
    justifyContent: "space-around",
  },
  use_case_intro_item: {
    "& .icon-box": {
      margin: "0 auto",
      width: 80,
      height: 80,
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 8,
      background: "#eff5fd",
    },
    "& .title": {
      marginTop: 20,
      fontSize: 20,
      ...theme.font_bold,
      textAlign: "center",
    },
    "& .desc": {
      marginTop: 8,
      color: "#747C84",
      textAlign: "center",
      letterSpacing: "-0.64px",
    },
  },
  use_case_interview_content: {
    marginTop: 120,
    "& .MuiDivider-root": {
      margin: "80px 0",
      background: "#DEE2E6",
    },
  },
  use_case_interview_item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 20,
    "& .use_case_interview_item_img": {
      flex: "1 1 600px",
      maxWidth: 600,
      height: 344,

      "& img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
    "& .use_case_interview_item_content": {
      flex: "1 1 589px",
      maxWidth: 589,
    },
    "& .sub-title": {
      color: service.primary_800,
      fontSize: 18,
      ...theme.font_medium,
    },
    "& .title": {
      fontSize: 32,
      color: theme.gray_8,
      ...theme.font_bold,
      letterSpacing: "-1.6px",
    },
    "& .desc": {
      marginTop: 32,
      color: "#747C84",
      letterSpacing: "-1.2px",
    },
    "& .view-more-btn": {
      marginTop: 32,
      paddingRight: 32 + 12,
      color: theme.gray_7_text,
      ...typo.b2_m,
      position: "relative",

      "&::before": {
        content: "''",
        position: "absolute",
        top: "50%",
        right: 0,
        width: 32,
        height: 32,
        transform: "translateY(-50%)",
        background: service.primary_50,
        borderRadius: "50%",
      },
      "&::after": {
        content: "''",
        position: "absolute",
        top: "50%",
        right: 8,
        width: 16,
        height: 16,
        transform: "translateY(-50%)",
        transition: "0.45s cubic-bezier(.785,.135,.15,.86)",
        backgroundColor: service.primary_600,
        maskImage: `url(${EastIcon})`,
        maskSize: "16px auto",
        maskPosition: "left top",
        maskRepeat: "repeat-x",
      },
      "&:hover::after": {
        backgroundPosition: "16px top",
        maskPosition: "16px top",
      },
    },
  },
  review_section: {
    width: "100%",
    paddingTop: 100,
    paddingBottom: 100,
    background: "#F5F8FF",
    color: theme.gray_9_title,
  },
  review_section_title: {
    ...landingTheme.typography.h3,
    ...theme.font_bold,
  },
  review_section_sub_title: {
    marginTop: 12,
    ...landingTheme.typography.body3,
    ...theme.font_medium,
    color: theme.gray_6,
  },
  review_slide_box: {
    marginTop: 50,
    marginLeft: -14,
    marginRight: -14,
    "& .slick-list": {
      padding: "10px 0",
    },
  },
  review_slide_item: {
    "&&": {
      margin: "0 14px",
      padding: 36,
      height: 360,
      display: "flex",
      flexDirection: "column",
      borderRadius: 12,
      border: 0,
      boxShadow: "1.104px 4.417px 19.876px 0 rgba(0, 0, 0, 0.04)",
    },
    "& .slide-item-title": {
      color: theme.gray_8,
      letterSpacing: -1.6,
      ...landingTheme.typography.body1,
      ...theme.font_bold,
      lineHeight: 1.5,
    },
    "& .slide-item-profile-box": {
      width: 80,
      height: 80,
      borderRadius: "50%",
      background: "#DCE6F3",
      position: "relative",
      marginLeft: "auto",
      overflow: "hidden",

      "& .profile-img": {
        position: "absolute",
        bottom: 0,
        left: "50%",
        transform: "translateX(-50%)",
      },
    },
    "& .slide-item-text": {
      marginTop: 20,
      color: theme.gray_6,
      letterSpacing: -1.2,
      fontSize: 17,
      lineHeight: 1.5,
    },
    "& .slide-item-bottom-divider": {
      width: "100%",
      height: 1,
      marginTop: 28,
      marginBottom: 20,
      background: theme.gray_3,
    },
    "& .slide-item-bottom-box": {
      alignItems: "center",
    },
    "& .slide-item-reviewer": {
      color: theme.gray_9_title,
      ...theme.font_bold,
    },
    "& .slide-item-divider": {
      margin: "0 12px",
      width: 1,
      height: 12,
      background: theme.gray_4,
    },
    "& .slide-item-logo": {
      // height: 24,
    },
  },
  slide_arrow_button: {
    "&&": {
      width: 80,
      height: 80,
      position: "absolute",
      top: -80,
      background: theme.white,
      borderRadius: "50%",
      border: `1px solid ${theme.gray_2}`,
      color: theme.gray_4,

      "& svg": {
        width: 32,
        height: 32,
      },
      "&::before": { display: "none", visibility: "hidden" },
      "&:hover": {
        color: theme.gray_7_text,
      },
      "&.slick-prev": {
        left: "unset",
        right: 0 + 80 + 28,
      },
      "&.slick-next": {
        right: 16,
      },
    },
  },

  slide_paging: {
    margin: "0 4px",
    width: 10,
    height: 10,
    borderRadius: "50%",
    background: theme.white,
    opacity: 0.5,
    transition: "all 0.3s",
  },

  banner: { background: "#0060FF", color: theme.white },
  banner_inner: {
    padding: "87px 0",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    background: `url(${BannerBook}) no-repeat top 60% left -5%, url(${BannerLock}) no-repeat center right -5%`,
    "& .banner-title": {
      fontSize: 46,
      ...theme.font_bold,
      textAlign: "center",
      textTransform: "uppercase",
    },
    "& .banner-description": {
      marginTop: 12,
      ...typo.b1_r,
    },
    "& .banner-button-box": {
      marginTop: 44,
      gap: 20,
    },
    "& .banner-button": {
      padding: "0 46px",
      height: 54,
      borderRadius: 67,
      fontSize: 18,
      ...theme.font_bold,
      border: `1px solid ${alpha(theme.white, 0.4)}`,
      transition: "all 0.1s ease-in",

      "&:hover": {
        background: theme.white,
        color: theme.black,
      },
    },
  },
  [MuiTheme.breakpoints.down("lg")]: {
    review_slide_box: {
      marginLeft: -10,
      marginRight: -10,
    },
  },

  [MuiTheme.breakpoints.down(landingTheme.layout.BREAK_MD)]: {
    visual_section: {
      paddingTop: 80,
    },
    visual_section_inner: {
      paddingTop: 60,
      paddingBottom: 100,
    },
    service_section_list_inner: {
      alignItems: "flex-start",
      gap: 20,
      "& .service-inner-img-box": {
        width: 520,
        height: "auto",
        aspectRatio: "520 / 314",
        borderRadius: 8,
        background: "#F6FBFF",
        display: "flex",
        position: "sticky",
        top: 180,
        right: 20,

        "& .img-wrap": {
          "&:nth-child(1), &:nth-child(3)": {
            "& img": { maxWidth: "90%" },
          },
        },
      },
    },
    service_list_item: {
      "&:nth-child(1), &:nth-child(3)": {
        "& .service-item-img-box img": { maxWidth: "90%" },
      },
      "& .service-item-img-box": {
        width: 520,
        height: "auto",
        aspectRatio: "520 / 314",
      },
      "& .service-item-title": { fontSize: 32 },
    },
    service_item_inner: {
      gap: 20,
      flexWrap: "wrap",

      "& .service-item-button-box": { flexWrap: "wrap" },
    },
    customers_section: {},
    customers_section_inner: {
      padding: "80px 0",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",

      "& .sub-title": { marginBottom: 8 },
      "& .title": { fontSize: 32, whiteSpace: "normal !important" },
    },
    customers_box: { marginTop: 50 },
    use_case_section: {},
    use_case_intro_content: {
      flexWrap: "wrap",
      maxWidth: 620,
      marginTop: 80,
      margin: "0 auto",
      justifyContent: "space-between",
    },
    use_case_intro_item: {
      width: 253,
      "&:nth-child(1)": { marginBottom: 60 },
    },
    use_case_interview_content: {},
    use_case_interview_item: {
      gap: 20,
      flexWrap: "wrap",
      "& .use_case_interview_item_img": {
        flex: 1,
        height: "auto",
        aspectRatio: "460 / 330",
        minWidth: 320,
      },
      "& .use_case_interview_item_content": {
        flex: "1",
        maxWidth: 508,
        minWidth: 360,
        order: 1,
      },
      "& .title": { whiteSpace: "pre-line", fontSize: 30, marginTop: 8 },
    },
    review_section: {},
    review_slide_item: {
      "&&": { width: 408 },
    },
    banner: {
      "& $container": {
        padding: "0",
      },
    },
    banner_inner: {
      paddingLeft: 20,
      paddingRight: 20,
      background: `url(${BannerBook}) no-repeat top 60% left -5% / 230px auto , url(${BannerLock}) no-repeat center right / 230px auto`,
    },
  },

  [MuiTheme.breakpoints.down(landingTheme.layout.BREAK_SM)]: {
    visual_section: {
      paddingTop: 76,
    },
    visual_section_inner: {
      "& .main-obj": { display: "none" },
      "& .visual_section_inner_title": { fontSize: 32 },
      "& .visual_section_inner_desc": {
        fontSize: 16,
        whiteSpace: "normal !important",
      },
      "& .rolling_box": { marginTop: 80 },
    },
    service_section_list: { paddingTop: 60, paddingBottom: 80 },
    service_item_inner: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: 40,
    },
    service_section_list_inner: {
      "& > .MuiBox-root": { width: "100%" },
    },
    service_list_item: {
      marginBottom: 100,
      "& $container": { padding: "0" },
      "& .service-item-badge": { ...typo.b3_b },
      "& .service-item-title": { fontSize: 24 },
      "& .service-item-img-box": { width: "100%" },
      "& .service-item-list-item": { fontSize: 14 },
      "& .service-item-button": { ...typo.b3_b },
    },
    customers_section_inner: {
      padding: "60px 0",

      "& .sub-title": { fontSize: 16 },
      "& .title": { whiteSpace: "pre-line !important", fontSize: 26 },
    },
    customers_box: {
      marginTop: 32,
      flexDirection: "column",
      alignItems: "center",
      gap: 40,

      "& .MuiDivider-root": {
        ...theme.hidden,
      },
    },
    customers_box_item: {
      "& .customers_box_item_number": { marginTop: 0, fontSize: 32 },
      "& .customers_box_item_text": { ...typo.b2_m },
    },
    use_case_section_inner: {
      paddingTop: 80,
      paddingBottom: 80,

      "& .section-title": { fontSize: 28 },
    },

    use_case_intro_content: {
      marginTop: 80,
      justifyContent: "center",
      gap: "60px",
    },
    use_case_intro_item: {
      width: "100%",
      "&:nth-child(1)": { marginBottom: 0 },
    },

    use_case_interview_content: {
      marginTop: 100,
      "& .MuiDivider-root": { margin: "60px 0" },
    },
    use_case_interview_item: {
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: 32,

      "& .use_case_interview_item_img": {
        width: "100%",
        aspectRatio: "560 / 330",
        minWidth: "0",
      },
      "& .use_case_interview_item_content": {
        width: "100%",
        maxWidth: "100%",
        minWidth: 0,
        "& .view-more-btn": { fontSize: 14 },
      },
      "& .sub-title": { fontSize: 16 },
      "& .title": { whiteSpace: "normal", fontSize: 24 },
      "& .desc": { marginTop: 28, fontSize: 14 },
    },
    review_section: {
      padding: "60px 0",
    },
    review_section_title: { fontSize: 28 },
    review_section_sub_title: { fontSize: 16 },
    review_slide_box: {
      marginTop: 80,
    },
    review_slide_item: {
      "&&": {
        width: 408,
        maxWidth: "calc(100vw - 48px)",
        minHeight: 360,
        height: "auto",
      },
      "& .slide-item-title": { marginRight: 8 },
      "& .slide-item-profile-box": { flexShrink: 0 },
    },
    slide_arrow_button: {
      "&&": { top: -40, width: 40, height: 40 },
      "&&.slick-prev": {
        left: 16,
        right: "unset",
      },
      "&&.slick-next": {
        left: 68,
        right: "unset",
      },
    },
    banner_inner: {
      padding: "60px 20px",
      background: "#0060FF",

      "& .banner-title": { fontSize: 28 },
      "& .banner-description": { ...typo.b2_r, whiteSpace: "pre-line" },
      "& .banner-button-box": {
        marginTop: 40,
        flexWrap: "wrap",
        justifyContent: "center",
        gap: 8,
      },
      "& .banner-button": {
        height: 46,
        padding: "0 40px",
        fontSize: 16,
      },
    },
  },
}));

export default useHomeStyles;
