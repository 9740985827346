import React from "react";
import DialogContainer from "./DialogContainer";
import PersistContainer from "./PersistContainer";
import SnackbarContainer from "./SnackbarContainer";

// 다양한 container 들을 포함하도록 감싸줌.
export default class ContainerProvider extends React.PureComponent {
  render() {
    return (
      <>
        <DialogContainer />
        <PersistContainer />
        <SnackbarContainer />
        {this.props.children}
      </>
    );
  }
}
