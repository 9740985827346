import React from "react";
import { Box, Container, ButtonBase } from "@material-ui/core";

import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import Flex from "@ui/goono/components/layouts/Flex";
import useHomeStyles from "../styles";
import { useHistory } from "react-router-dom";
import { ScreenURL } from "src/routes/route_list";

export default function MainBanner() {
  const classes = useHomeStyles();
  const history = useHistory();
  return (
    <Box className={classes.banner}>
      <Container className={classes.container}>
        <Flex container className={classes.banner_inner}>
          <GoonoTypography
            className="banner-title"
            children="Prove your work"
            keepAll
          />
          <GoonoTypography
            className="banner-description"
            keepAll
            children={
              "전자연구노트, 문서보안공유 서비스를 통해 \n 당신의 위대한 노력을 증명하고 보호합니다."
            }
          />
          <Flex container className="banner-button-box">
            <ButtonBase
              className="banner-button"
              onClick={() => {
                history.push(ScreenURL.CONTACT);
              }}
              children={" 문의하기"}
            />
            <ButtonBase
              className="banner-button"
              onClick={() => {
                history.push(ScreenURL.PRICE);
              }}
              children={"가격안내"}
            />
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
}
