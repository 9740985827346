import React from "react";
import { Box, Container, Divider, ButtonBase } from "@material-ui/core";

import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import Flex from "@ui/goono/components/layouts/Flex";
import useHomeStyles from "../styles";
import { useHistory } from "react-router-dom";
import { ScreenURL } from "src/routes/route_list";

import { IMAGES } from "@assets/images/public";

export default function MainUseCaseSection() {
  const classes = useHomeStyles();
  return (
    <Box className={classes.use_case_section}>
      <Container className={classes.container}>
        <Box className={classes.use_case_section_inner}>
          <GoonoTypography
            children="활용 사례"
            type="h2"
            textcolor="title"
            className="section-title"
          />
          <UseCaseIntroContent />
          <UseCaseInterviewContent />
        </Box>
      </Container>
    </Box>
  );
}

function UseCaseInterviewContent() {
  const classes = useHomeStyles();

  const useCaseInterviewItems: UseCaseInterviewItemProps[] = [
    {
      imgPosition: "left",
      imgSrc: IMAGES.landingHomeScreen.useCase.useCaseImg01,
      subTitle: "R&D 정부사업을 진행하는 스타트업",
      title: "IT 스타트업에게는 \n 가뭄에 단비 같은 구노",
      desc: `저희 같은 IT 기업이라면, 코드를 이용하는 작업이 대부분이라
연구노트에 대한 막막함이 있을 것 같습니다.
하지만 구노를 통해 기존에 작성한 Github 코드를 단 한번 연동해, 파일 모두 연구노트로 
인정받을 수 있다는 부분이 혁신이었습니다.`,
      linkUrl: ScreenURL.USECASE_STARTUP,
    },
    {
      imgPosition: "right",
      imgSrc: IMAGES.landingHomeScreen.useCase.useCaseImg02,
      subTitle: "체계적인 연구데이터를 안전하게 관리하는 기관",
      title: "대규모 연구조직에 \n반드시 필요한 솔루션",
      desc: `각 연구원이 어느 부분에 기여를 했고, 어느 정도 기여를 했는지 파악할 수 있어
연구 관리자의 입장에서도 매우 편리해졌습니다.
사용성 높은 UXUI 로 연구노트에 대한 연구원들의 진입 장벽을 낮출 수 있었고, 
구성원의 참여도를 확인할 수 있는 관리자 모드 기능을 만족스럽게 사용하고 있습니다.`,
      linkUrl: ScreenURL.USECASE_DIGITAL_TRANSFORMATION,
    },
    {
      imgPosition: "left",
      imgSrc: IMAGES.landingHomeScreen.useCase.useCaseImg03,
      subTitle: "외부 협업이 많은 프리랜서",
      title: "마음놓고 협업할 수 있는\n 든든한 이유",
      desc: `저 같은 프리랜서는 특성상, 강력하고 효율적인 툴을 발굴하고 잘 활용하는 것이
곧 경쟁력이라고 생각하고 있어요.
스트롱박스가 그런 툴이라고 생각합니다. 저작권, 원본을 지켜주고 효율적인 
외부 협력을 돕는 가장 안전하고 스마트한 툴이요.`,
      linkUrl: ScreenURL.USECASE_FREELANCER,
    },
  ];

  return (
    <Box className={classes.use_case_interview_content}>
      {useCaseInterviewItems.map((item, index) => (
        <React.Fragment key={index}>
          <UseCaseInterviewItem key={index} {...item} />
          {index < 2 && <Divider orientation="horizontal" />}
        </React.Fragment>
      ))}
    </Box>
  );
}

type UseCaseInterviewItemProps = {
  imgPosition: "left" | "right";
  imgSrc: string;
  subTitle: string;
  title: string;
  desc: string;
  linkUrl: string;
};

function UseCaseInterviewItem({
  imgPosition,
  imgSrc,
  subTitle,
  title,
  desc,
  linkUrl,
}: UseCaseInterviewItemProps) {
  const classes = useHomeStyles();
  const history = useHistory();

  const renderImg = () => (
    <Box className={"use_case_interview_item_img"}>
      <img src={imgSrc} alt={title} />
    </Box>
  );
  const renderContent = () => (
    <Box className={"use_case_interview_item_content"}>
      <GoonoTypography children={subTitle} className="sub-title" />
      <GoonoTypography
        children={title}
        type="h3"
        textcolor="title"
        className="title"
        keepAll
      />
      <GoonoTypography children={desc} className="desc" preLine keepAll />
      <ButtonBase
        onClick={() => {
          history.push(linkUrl);
        }}
        className="view-more-btn"
      >
        <span>자세히 보기</span>
      </ButtonBase>
    </Box>
  );
  const renderImgPosLeft = () => {
    return (
      <React.Fragment>
        {renderImg()}
        {renderContent()}
      </React.Fragment>
    );
  };
  const renderImgPosRight = () => {
    return (
      <React.Fragment>
        {renderContent()}
        {renderImg()}
      </React.Fragment>
    );
  };

  return (
    <Box className={classes.use_case_interview_item}>
      {imgPosition === "left" ? renderImgPosLeft() : renderImgPosRight()}
    </Box>
  );
}

function UseCaseIntroContent() {
  const classes = useHomeStyles();

  const useCaseIntroItems = [
    {
      icon: <IMAGES.landingHomeScreen.useCase.useCaseIcon01 />,
      title: "스타트업",
      desc: "IT, 뷰티, 바이오, 제조까지\n모든 스타트업의 R&D를 돕는 연구노트",
    },
    {
      icon: <IMAGES.landingHomeScreen.useCase.useCaseIcon02 />,
      title: "연구소",
      desc: "안전하고, 쉽고, 빠른 연구 기록과 관리로\n연구 효율 증대와 연구 과업 달성",
    },
    {
      icon: <IMAGES.landingHomeScreen.useCase.useCaseIcon03 />,
      title: "일반기업",
      desc: "중요한 기술문서, 블록체인 인증과\n활동기록 관리로 안전하게 보관",
    },
    {
      icon: <IMAGES.landingHomeScreen.useCase.useCaseIcon04 />,
      title: "프리랜서",
      desc: "원본 문서 보안, 스마트한 공유 기능,\n간편한 NDA 서명 요청까지",
    },
  ];

  return (
    <Box className={classes.use_case_intro_content}>
      {useCaseIntroItems.map((item, index) => (
        <UseCaseIntroItem key={index} {...item} />
      ))}
    </Box>
  );
}

type UseCaseIntroItemProps = {
  icon: React.ReactNode;
  title: string;
  desc: string;
};

function UseCaseIntroItem({ icon, title, desc }: UseCaseIntroItemProps) {
  const classes = useHomeStyles();

  return (
    <Box className={classes.use_case_intro_item}>
      <Flex container className="icon-box">
        {icon}
      </Flex>
      <GoonoTypography children={title} textcolor="title" className="title" />
      <GoonoTypography children={desc} className="desc" preLine />
    </Box>
  );
}
