import { ScreenURL } from "./route_list";
import { lazy } from "react";
import { RouteProps } from "react-router-dom";
import HomeScreen from "src/screens/HomeScreen";

const routes_landing: Array<RouteProps> = [
  {
    path: ScreenURL.HOME,
    exact: true,
    component: HomeScreen,
  },
  {
    path: ScreenURL.PRICE,
    exact: true,
    component: lazy(() => import("../screens/PriceScreen/index")),
  },
  {
    path: ScreenURL.SERVICE_GOONO,
    exact: true,
    component: lazy(
      () => import("../screens/Product/ResearchNoteServiceScreen/index")
    ),
  },
  {
    path: ScreenURL.SERVICE_STRONGBOX,
    exact: true,
    component: lazy(
      () => import("../screens/Product/StrongBoxServiceScreen/index")
    ),
  },
  {
    path: ScreenURL.SERVICE_BUILTIN,
    exact: true,
    component: lazy(
      () => import("../screens/Product/BuiltinServiceScreen/index")
    ),
  },
  {
    path: ScreenURL.CONTACT,
    exact: true,
    component: lazy(() => import("../screens/ContactScreen/index")),
  },
  {
    path: ScreenURL.CONTACT_TYPE,
    exact: true,
    component: lazy(() => import("../screens/ContactScreen")),
  },
  {
    path: ScreenURL.USECASE_STARTUP,
    exact: true,
    component: lazy(() => import("../screens/UseCase/Pages/StartUpScreen")),
  },
  {
    path: ScreenURL.USECASE_DIGITAL_TRANSFORMATION,
    exact: true,
    component: lazy(
      () => import("../screens/UseCase/Pages/DigitalTransformationScreen")
    ),
  },
  {
    path: ScreenURL.USECASE_DOC_SHARING,
    exact: true,
    component: lazy(() => import("../screens/UseCase/Pages/DocSharingScreen")),
  },
  {
    path: ScreenURL.USECASE_FREELANCER,
    exact: true,
    component: lazy(() => import("../screens/UseCase/Pages/FreelancerScreen")),
  },
];

const routes_common: Array<RouteProps> = [
  {
    path: ScreenURL.TERM,
    exact: true,
    component: lazy(() => import("../screens/common/TermScreen")),
  },
  {
    path: ScreenURL.TERM_INFO,
    exact: true,
    component: lazy(() => import("../screens/common/TermScreen")),
  },
];

export { routes_landing, routes_common };
