import React from "react";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import ContainerProvider from "./redux/container";
import GoonoThemeProvider, {
  GoonoTheme,
} from "@ui/goono/styles/GoonoThemeProvider";
import { ThemeProvider as EmotionThemeProvider } from "@emotion/react";
import { configLogLevel, DEBUG, INFO } from "@redwit-commons/utils/log";
import {
  setBgTaskLauncher,
  mkIdleBgTaskLauncher,
} from "@redwit-commons/utils/bgtask";
import { QueryClient, QueryClientProvider } from "react-query";
import MetaPixel from "./services/MetaPixel";
import GoonoRoutingManager from "./routes/GoonoRoutingManager";
import { BrowserRouter } from "react-router-dom";
import { ReactChannelIO } from "react-channel-plugin";
import { HelmetProvider } from "react-helmet-async";
import { ScreenURL } from "./routes/route_list";

configLogLevel({
  ReduxComponent: DEBUG,
  ReduxComponent3: DEBUG,
  NavigationState: DEBUG,
  StateMachine2: DEBUG,
  StateMachine3: DEBUG,
  "service/manager": INFO,
  "service/payment": INFO,
  "service/user": INFO,
  service: INFO,
  FutureComponent: INFO,
});

setBgTaskLauncher(mkIdleBgTaskLauncher());

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

export default function App() {
  React.useEffect(() => {
    console.log(`env : `, process.env);
  }, []);

  React.useEffect(() => {
    const url = window.location.href;

    if (url === "https://www.goono.so/service") {
      window.location.href = ScreenURL.SERVICE_GOONO;
    } else if (url === "https://www.goono.so/service/built-in") {
      window.location.href = ScreenURL.SERVICE_BUILTIN;
    } else if (url === "https://www.goono.so/service/data-room") {
      window.location.href = ScreenURL.SERVICE_STRONGBOX;
    } else if (url === "https://www.goono.so/sign-in") {
      window.location.href = `${process.env.REACT_APP_SERVICE_URL!}/`;
    } else if (url === "https://www.goono.so/sign-up") {
      window.location.href = `${process.env.REACT_APP_SERVICE_URL!}/`;
    }
  }, []);

  return (
    <ServiceProvider>
      <GoonoRoutingManager />
    </ServiceProvider>
  );
}

function ServiceProvider(props: { children: React.ReactChild }) {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <EmotionThemeProvider theme={GoonoTheme}>
          <GoonoThemeProvider>
            <PersistGate loading={null} persistor={persistor}>
              <ContainerProvider>
                <MetaPixel pixelId="6764709906940838">
                  <HelmetProvider>
                    <BrowserRouter>
                      <ReactChannelIO
                        autoBoot
                        autoBootTimeout={500}
                        pluginKey="48018974-9136-4ba8-95fd-0af0c15d3018"
                      >
                        {props.children}
                      </ReactChannelIO>
                    </BrowserRouter>
                  </HelmetProvider>
                </MetaPixel>
              </ContainerProvider>
            </PersistGate>
          </GoonoThemeProvider>
        </EmotionThemeProvider>
      </Provider>
    </QueryClientProvider>
  );
}
