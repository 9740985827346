import React from "react";
import { Box, Container, ButtonBase, Paper, Divider } from "@material-ui/core";

import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import Flex from "@ui/goono/components/layouts/Flex";
import useHomeStyles from "../styles";
import clsx from "clsx";

import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import { IMAGES } from "@assets/images/public";

import Slider, {
  Settings,
  ResponsiveObject,
  CustomArrowProps,
} from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import translate from "@translate";

type SlideItemData = {
  title: string;
  text: string;
  reviewer: string;
  logoSrc: string;
};

export default function MainReviewSection() {
  const classes = useHomeStyles();

  return (
    <Box className={classes.review_section} component="section">
      <Container className={classes.container}>
        <Box>
          <GoonoTypography type="h2" className={classes.review_section_title}>
            {translate.landing_home.review.title}
          </GoonoTypography>
          <GoonoTypography className={classes.review_section_sub_title} keepAll>
            {translate.landing_home.review.sub_title}
          </GoonoTypography>
        </Box>
        <ReviewSlide />
      </Container>
    </Box>
  );
}

type SlideArrowProps = { direction: "prev" | "next" } & CustomArrowProps;

function SlideArrow(props: SlideArrowProps) {
  const { onClick, style, className, direction } = props;
  const classes = useHomeStyles();

  const arrowIcon =
    direction === "prev" ? (
      <ChevronLeftRoundedIcon />
    ) : (
      <ChevronRightRoundedIcon />
    );

  return (
    <ButtonBase
      onClick={onClick}
      className={clsx(className, classes.slide_arrow_button)}
      style={style}
    >
      {arrowIcon}
    </ButtonBase>
  );
}

function SlidePaging() {
  const classes = useHomeStyles();

  return <div className={classes.slide_paging}></div>;
}

function ReviewSlideItem({
  title,
  text,
  reviewer,
  logoSrc,
  index,
}: SlideItemData & { index: number }) {
  const classes = useHomeStyles();

  const reviewers = [
    IMAGES.landingHomeScreen.review.reviewer01,
    IMAGES.landingHomeScreen.review.reviewer02,
    IMAGES.landingHomeScreen.review.reviewer03,
  ];
  const reviewerImg = reviewers[index % reviewers.length];

  return (
    <Paper className={classes.review_slide_item}>
      <Flex container className="slide-item-top-box">
        <GoonoTypography type="h3" className="slide-item-title" preLine>
          {title}
        </GoonoTypography>
        <Box className="slide-item-profile-box">
          <img src={reviewerImg} alt="" className="profile-img" />
        </Box>
      </Flex>
      <GoonoTypography className="slide-item-text" keepAll>
        {text}
      </GoonoTypography>
      <Divider className="slide-item-bottom-divider" />
      <Flex container className="slide-item-bottom-box">
        <GoonoTypography className="slide-item-reviewer">
          {reviewer}
        </GoonoTypography>
        <hr className="slide-item-divider" />
        <img src={logoSrc} alt="" className="slide-item-logo" />
      </Flex>
    </Paper>
  );
}
function ReviewSlide() {
  const classes = useHomeStyles();
  const slider = React.createRef<Slider>();

  const SlideItemDataList: SlideItemData[] = [
    {
      title: translate.landing_home.review.item_01.title,
      text: translate.landing_home.review.item_01.text,
      reviewer: translate.landing_home.review.item_01.reviewer,
      logoSrc: IMAGES.landingHomeScreen.review.logoImmuno,
    },
    {
      title: translate.landing_home.review.item_02.title,
      text: translate.landing_home.review.item_02.text,
      reviewer: translate.landing_home.review.item_02.reviewer,
      logoSrc: IMAGES.landingHomeScreen.review.logoIae,
    },
    {
      title: translate.landing_home.review.item_03.title,
      text: translate.landing_home.review.item_03.text,
      reviewer: translate.landing_home.review.item_03.reviewer,
      logoSrc: IMAGES.landingHomeScreen.review.logoIntek,
    },
    {
      title: translate.landing_home.review.item_04.title,
      text: translate.landing_home.review.item_04.text,
      reviewer: translate.landing_home.review.item_04.reviewer,
      logoSrc: IMAGES.landingHomeScreen.review.logoSangji,
    },
    {
      title: translate.landing_home.review.item_05.title,
      text: translate.landing_home.review.item_05.text,
      reviewer: translate.landing_home.review.item_05.reviewer,
      logoSrc: IMAGES.landingHomeScreen.review.logoNxv,
    },
    {
      title: translate.landing_home.review.item_06.title,
      text: translate.landing_home.review.item_06.text,
      reviewer: translate.landing_home.review.item_06.reviewer,
      logoSrc: IMAGES.landingHomeScreen.review.logoEwha,
    },
    {
      title: translate.landing_home.review.item_07.title,
      text: translate.landing_home.review.item_07.text,
      reviewer: translate.landing_home.review.item_07.reviewer,
      logoSrc: IMAGES.landingHomeScreen.review.logoRoot,
    },
  ];

  const responsiveSettings: ResponsiveObject[] = [
    {
      breakpoint: 1081,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        infinite: true,
        variableWidth: true,
        dots: false,
        appendDots: undefined,
        customPaging: undefined,
      },
    },
  ];
  const settings: Settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    responsive: responsiveSettings,
    prevArrow: <SlideArrow direction="prev" />,
    nextArrow: <SlideArrow direction="next" />,
    customPaging: () => <SlidePaging />,
  };
  return (
    <Box className={classes.review_slide_box}>
      <Slider ref={slider} {...settings}>
        {SlideItemDataList.map((item, index) => {
          return <ReviewSlideItem key={item.title} {...item} index={index} />;
        })}
      </Slider>
    </Box>
  );
}
