import React from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";

type SEOMetaProps = {
  title: string;
  description: string;
};

export default function SEOMeta({ title, description }: SEOMetaProps) {
  const history = useHistory();
  const path = history.location.pathname;

  return (
    <Helmet>
      {/* <!-- default option --> */}
      <meta charSet="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <meta name="robots" content="index,follow,noodp" />
      <meta name="revisit-after" content="1 days" />
      <meta name="author" content="namu" />
      <meta name="content-language" content="ko" />
      <meta name="theme-color" content="#000000" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no"
      />
      {/* <!-- SEO --> */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={`https://www.goono.so${path}`} />
      <meta
        name="keywords"
        content="구노, goono, 스트롱박스, strongbox, 연구노트, 전자연구노트, eln, 온프레미스 연구노트, 구축형 연구노트, 클라우드형 연구노트, 데이터룸, 문서보안, 문서공유"
      />
      {/* <!-- Open Graph --/> */}
      <meta property="og:type" content="website" />
      <meta
        property="og:site_name"
        content="전자연구노트 구노, 문서 보안/공유 솔루션 스트롱박스"
      />
      <meta property="og:locale" content="ko_KR" />

      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:image:type" content="image/png" />
      <meta
        property="og:image:alt"
        content="전자연구노트 구노와 문서 보안/공유 솔루션 스트롱박스"
      />
      <meta property="og:url" content={`https://www.goono.so${path}`} />
    </Helmet>
  );
}
