import React from "react";
import loadable from "@loadable/component";

const LandingGoonoBaseGridLazy = loadable(
  () => import(`./GoonoBaseGridByRouteType/Landing`)
);

export type GoonoBaseGridProps = {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  viewerOption: {
    type: "landing_page";
  };
};

export default function GoonoBaseGrid(props: GoonoBaseGridProps) {
  const { viewerOption } = props;

  /** 랜딩 페이지 baseGrid */
  if (viewerOption.type === "landing_page") {
    return <LandingGoonoBaseGridLazy {...props} />;
  }
  return <></>;
}
