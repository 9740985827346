export default {
  ko: {
    footer: {
      term: "서비스 이용약관",
      privace: "개인정보처리방침",
      company: "사업자 번호 : 144-87-01498",
      call: "대표번호 : 042-861-5001 ",
      fax: "Fax : 042-861-5002",
      email: "E-mail : contact@redwit.io",
      loc: "(34139) 대전광역시 유성구 어은로 44, 한빛빌딩 2층",
    },
  },
  en: {
    footer: {
      term: "Service Term (KOR)",
      privace: "Privacy Policy (KOR)",
      company: "Business Number 144-87-01498",
      call: "Tel : 042-861-5001 ",
      fax: "Fax : 042-861-5002",
      email: "E-mail : contact@redwit.io",
      loc: "(2nd floor) 44, Eoeun-ro, Yuseong-gu, Daejeon, Republic of Korea",
    },
  },
};
