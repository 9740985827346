import React from "react";
import { Box } from "@material-ui/core";
import useHomeStyles from "../styles";
import ServiceListSectionItem, {
  ServiceListItem,
} from "./ServiceListSectionItem";
import { IMAGES } from "@assets/images/public";
import { ScreenURL } from "src/routes/route_list";
import { landingTheme } from "src/styles/landingTheme";
import useDebounceWindowWidth from "@utils/hooks/pure/useDebounceWindowWidth";

export default function MainServiceListSection() {
  const classes = useHomeStyles();
  const [currentSection, setCurrentSection] = React.useState<number>(1);
  const windowWidth = useDebounceWindowWidth();
  const isMobile = windowWidth < landingTheme.layout.BREAK_SM;

  const ServiceSectionArray: ServiceListItem[] = [
    {
      imgSrc: IMAGES.landingHomeScreen.contentImage.contentSection01,
      badgeTitle: "연구노트를 가장 쉽고 빠르게",
      title: "전자연구노트 구노",
      linkUrl: ScreenURL.SERVICE_GOONO,
      list: [
        {
          text: "PC, 모바일로 언제 어디서든 편하고 빠르게",
        },
        {
          text: "Github, 구글 드라이브 등 외부 서비스 연동",
        },
        {
          text: "국가연구개발사업 연구노트 지침법령을 충족",
        },
        {
          text: "관리자 대시보드로 편리한 멤버 관리 및 데이터 취합",
        },
      ],
    },
    {
      imgSrc: IMAGES.landingHomeScreen.contentImage.contentSection02,
      badgeTitle: "연구기관의 디지털 혁신을 완성하는",
      title: "구축형 전자연구노트",
      linkUrl: ScreenURL.SERVICE_BUILTIN,
      list: [
        {
          text: "엔터프라이즈급 보안 및 규정 준수",
        },
        {
          text: "전자연구노트 규정, GxP, 21 CFR Part 11 등 데이터 표준 완벽 대응",
        },
        {
          text: "블록체인, 분산 클라우드 기술을 통한 위변조 방지",
        },
        {
          text: "NarDa, DataWorks 등 연구데이터 레포지터리 연동",
        },
        {
          text: "eGovFramework 기반 시스템, ISO 27001/GS 인증으로 검증된 품질",
        },
      ],
    },
    {
      imgSrc: IMAGES.landingHomeScreen.contentImage.contentSection03,
      badgeTitle: "철저한 보안, 스마트한 공유",
      title: "문서보안공유 솔루션 \n 스트롱박스",
      linkUrl: ScreenURL.SERVICE_STRONGBOX,
      list: [
        {
          text: "공유링크 생성하고  간편하고 안전하게 관리!",
        },
        {
          text: "인사이트 모니터링을 통한 문서 피드백!",
        },
        {
          text: "추가 공증이 필요없는 비밀유지서약서!",
        },
        {
          text: "강력한 보안 기술로  문서를 가장 안전하게!",
        },
      ],
    },
  ];

  const handleScroll = React.useCallback(
    () => {
      const scrollBottom = window.scrollY - window.innerHeight * 0.5;

      const sectionOffsets = ServiceSectionArray.map((_section, index) => {
        const el = document.getElementById(`scrollPoint-${index + 1}`);
        return el?.offsetTop ?? 0;
      });

      for (let i = 0; i < sectionOffsets.length; i++)
        if (scrollBottom >= sectionOffsets[i]) setCurrentSection(i + 1);
    },
    // debounce(() => {
    // 	const scrollBottom = window.scrollY - window.innerHeight * 0.5;

    // 	const sectionOffsets = ServiceSectionArray.map((_section, index) => {
    // 		const el = document.getElementById(`scrollPoint-${index + 1}`);
    // 		return el?.offsetTop ?? 0;
    // 	});

    // 	for (let i = 0; i < sectionOffsets.length; i++)
    // 		if (scrollBottom >= sectionOffsets[i]) setCurrentSection(i + 1);
    // }, 5),
    []
  );

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      // handleScroll.cancel();
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const renderCurrentSectionImg = (index: number, imgSrc: string) => {
    return (
      <Box className="img-wrap">
        {/* <Zoom in={index === currentSection - 1} timeout={300}> */}
        {index === currentSection - 1 && <img src={imgSrc} alt="" />}
        {/* </Zoom> */}
      </Box>
    );
  };

  return (
    <Box className={classes.service_section_list}>
      <Box className={classes.service_section_list_inner}>
        <Box>
          {ServiceSectionArray.map((item, index) => {
            const sectionNumber = index + 1;
            return (
              <ServiceListSectionItem
                key={item.title}
                {...item}
                id={`scrollPoint-${sectionNumber}`}
              />
            );
          })}
        </Box>
        {!isMobile && (
          <Box className="service-inner-img-box">
            {ServiceSectionArray.map((item, index) => {
              return renderCurrentSectionImg(index, item.imgSrc);
            })}
          </Box>
        )}
      </Box>
    </Box>
  );
}
