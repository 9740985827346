import React from "react";
import { Box, Container, List, ListItem, ButtonBase } from "@material-ui/core";
import Flex from "@ui/goono/components/layouts/Flex";
import GoonoTypography from "@ui/goono/components/typo/GoonoTypography";
import useHomeStyles from "../styles";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { ScreenURL } from "src/routes/route_list";
import { ICONS } from "@assets/images/public";
import useDebounceWindowWidth from "@utils/hooks/pure/useDebounceWindowWidth";
import { landingTheme } from "src/styles/landingTheme";

type ServiceDescriptionList = {
  text: string;
};
export type ServiceListItem = {
  imgSrc: string;
  badgeTitle: string;
  title: string;
  list: ServiceDescriptionList[];
  linkUrl: string;
};

type ServiceListItemProps = {
  id: string;
} & ServiceListItem;

export default function ServiceListSectionItem({
  imgSrc,
  badgeTitle,
  title,
  list,
  linkUrl,
  id,
}: ServiceListItemProps) {
  const classes = useHomeStyles();
  const history = useHistory();
  const windowWidth = useDebounceWindowWidth();
  const isMobile = windowWidth < landingTheme.layout.BREAK_SM;

  const renderImageBox = () => {
    return (
      <Box className="service-item-img-box">
        <img src={imgSrc} alt={title} />
      </Box>
    );
  };

  const renderDescriptionList = () => {
    return (
      <List className="service-item-list">
        {list.map((li) => {
          return (
            <ListItem className="service-item-list-item" key={li.text}>
              <span># {li.text}</span>
            </ListItem>
          );
        })}
      </List>
    );
  };

  const renderDescriptoinBox = () => {
    return (
      <Box className="service-item-description-box">
        <Box className="service-item-badge">{badgeTitle}</Box>
        <GoonoTypography
          type="h2"
          className="service-item-title"
          preLine
          keepAll
        >
          {title}
        </GoonoTypography>
        {renderDescriptionList()}
        <Flex container className="service-item-button-box">
          <ButtonBase
            className="service-item-button"
            onClick={() => {
              history.push(linkUrl);
            }}
          >
            <span>더 많은 기능 확인하기</span>
            <ICONS.east />
          </ButtonBase>
          <ButtonBase
            className="service-item-button"
            onClick={() => {
              history.push(ScreenURL.CONTACT);
            }}
          >
            <span>문의하기</span>
            <ICONS.east />
          </ButtonBase>
        </Flex>
      </Box>
    );
  };

  return (
    <Box id={id} className={clsx(classes.service_list_item)}>
      <Container className={classes.container}>
        <Flex
          container
          className={clsx(
            classes.service_item_inner,
            classes.service_item_inner_active
          )}
        >
          {renderDescriptoinBox()}
          {isMobile && renderImageBox()}
        </Flex>
      </Container>
    </Box>
  );
}
