import React from "react";
import { Route, RouteProps } from "react-router-dom";

export const CommonRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  return (
    <React.Suspense fallback={<></>}>
      <Route {...rest} />
    </React.Suspense>
  );
};
