import { combineReducers } from "redux";
import persist from "./persist";
import dialog from "./dialog";
import snackbar from "./snackbar";

const rootReducer = combineReducers({
  persist,
  dialog,
  snackbar,
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
