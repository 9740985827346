import faqs from "./faq";
import screens from "./screens";
import alert from "./alert";
import error from "./error";
import buttons from "./buttons";
import placeholders from "./placeholders";
import form from "./form";
import modal from "./modal";
import footer from "./footer";
import landing from "./landing";
import snackbar from "./snackbar";
import note from "./v3-translate/note";

export const DEFAULT_LANG = "ko" as const;
import { mkTranslateExport } from "@redwit-react-commons/hooks/translate";

const translate = {
  ko: {
    ...faqs.ko,
    ...screens.ko,
    ...alert.ko,
    ...error.ko,
    ...buttons.ko,
    ...placeholders.ko,
    ...form.ko,
    ...modal.ko,
    ...footer.ko,
    ...landing.ko,
    ...snackbar.ko,
    ...note.ko,

    won: "원",
    count: "개",
    help_center: "Goono 헬프센터",
  },
  en: {
    ...faqs.en,
    ...screens.en,
    ...alert.en,
    ...error.en,
    ...buttons.en,
    ...placeholders.en,
    ...form.en,
    ...modal.en,
    ...footer.en,
    ...landing.en,
    ...snackbar.en,
    ...note.en,

    won: "won",
    count: "",
    team_plan: "Team Plan",
    help_center: "Help center",
  },
};

export default mkTranslateExport(translate, "ko", "goono");
