import React from "react";
import { Route, RouteProps } from "react-router-dom";
import GoonoBaseGrid from "@utils/templates/GoonoBaseGrid";

export const LandingRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  return (
    <React.Suspense
      fallback={<GoonoBaseGrid viewerOption={{ type: "landing_page" }} />}
    >
      <Route {...rest} />
    </React.Suspense>
  );
};
