import { pxToRem } from "@ui/goono/styles/theme";
import { theme } from "@theme";

const COLORS = {
  FOOTER_BG: "#1c2d4d",
  REVIEW_BG: "#173a77",
  BANNER_BG: "#5463eb",
  CONTENT_BG_1: "#dde9ff",
  CONTENT_BG_2: "#f2f5fb",
  CONTENT_BG_3: "#dee2e9",
  CONTENT_BG_4: "#f4f6f9",
};

const TYPOGRAPHY = {
  h1: {
    fontSize: pxToRem(76),
    fontWeight: theme.font_bold,
    lineHeight: 1.2,
  },
  h2: {
    fontSize: pxToRem(40),
    fontWeight: theme.font_bold,
    lineHeight: 1.2,
  },
  h3: {
    fontSize: pxToRem(36),
    fontWeight: theme.font_medium,
    lineHeight: 1.2,
  },
  body1: { fontSize: pxToRem(22), lineHeight: 1.2 },
  body2: { fontSize: pxToRem(20), lineHeight: 1.6 },
  body3: { fontSize: pxToRem(18), lineHeight: 1.2 },
  body4: { fontSize: pxToRem(16), lineHeight: 1.2 },
  caption: { fontSize: pxToRem(14), lineHeight: 1.2 },
};

const LAYOUT = {
  MAX_CONTENT: 1280,
  MAX_CONTAINER: {
    maxWidth: 1280 + 40,
    padding: "0 20px",
  },
  BREAK_LG: 1321,
  BREAK_MD: 1081,
  BREAK_SM: 601,
};

export const landingTheme = {
  colors: COLORS,
  typography: TYPOGRAPHY,
  layout: LAYOUT,
};

// 타입 정의
export type LandingTheme = typeof landingTheme;
